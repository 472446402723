import {
  Country,
  Ethnicity,
  Gender,
  HcpTypes,
  StateOrProvince,
  UserState,
  UserType,
} from '@keyops-hcp/constants';
import { PharmacistDto } from './pharmacist.dto';
import { PhysicianDto } from './physician.dto';
import { NurseDto } from './nurse.dto';
import { ModeratorDto } from './moderator.dto';

export type AuthTypes = 'Google' | 'Keyops';

export interface UserDto {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  state?: UserState;
  yearOfBirth?: string;
  dateOfBirth?: Date;
  gender?: Gender;
  smsNotifications?: boolean;
  mobileNumber?: string;
  country?: Country;
  ethnicity?: Ethnicity[];
  provinceOfPractice?: StateOrProvince;
  cityOfPractice?: string;
  engagementPreference?: string[];
  authType?: AuthTypes;
  languagePreference?: string;
  hcpType: UserType;
  surveyContactId?: string;
  hcpProfile?: PhysicianDto | PharmacistDto | NurseDto | ModeratorDto;
  createdAt?: Date;
}

export interface PhysicianUserDto extends UserDto {
  hcpType: 'physician';
  hcpProfile: PhysicianDto;
}

export interface PharmacistUserDto extends UserDto {
  hcpType: 'pharmacist';
  hcpProfile: PharmacistDto;
}

export interface NurseUserDto extends UserDto {
  hcpType: 'nurse';
  hcpProfile: NurseDto;
}

export interface ModeratorUserDto extends UserDto {
  hcpType: 'moderator';
  hcpProfile: ModeratorDto;
}

export function isPhysicianUser(user: UserDto): user is PhysicianUserDto {
  return user.hcpType === 'physician';
}

export function isPharmacistUser(user: UserDto): user is PharmacistUserDto {
  return user.hcpType === 'pharmacist';
}

export function isNurseUser(user: UserDto): user is NurseUserDto {
  return user.hcpType === 'nurse';
}

export function isModeratorUser(user: UserDto): user is ModeratorUserDto {
  return user.hcpType === 'moderator';
}

export function isHcpUser(
  user: UserDto,
): user is PhysicianUserDto | PharmacistUserDto | NurseUserDto {
  const hcpTypes: readonly string[] = HcpTypes;
  return hcpTypes.includes(user.hcpType);
}
